<template>
  <div class="container wrapper">
    <div class="content">
      <div class="con">
        <div class="tab">
          <h3>个人中心</h3>
          <ul>
            <li
              v-for="(item, index) in tab"
              :key="index"
              :class="[currentIndex == index ? 'active' : '']"
              @click="changeTab(index, item.path)"
            >
              {{ item.title }}
            </li>
          </ul>
        </div>
        <router-view></router-view>
      </div>
    </div>
    <BaseCopyright />
  </div>
</template>
<script>
export default {
  data() {
    return {
      preSrc: config.src,
      listLoad: false, //list loading
      loading: true, //整体loading
      tab: [
        {
          title: "我的工作台",
          path: "/profile/workplace",
        },
        {
          title: "我的游戏",
          path: "/profile/game",
        },
        {
          title: "我的课程",
          path: "/profile/course",
        },
        {
          title: "我的收藏",
          path: "/profile/favor",
        },
        {
          title: "我的订单",
          path: "/profile/order",
        }, 
        {
          title: "我的分类",
          path: "/profile/baseCate",
        },
      ],
      currentIndex: 0,
    };
  },
  created() {
    let _self = this;
    _self.currentIndex = _self.$route.meta.idx;
  },
  methods: {
    changeTab(index, path) {
      let _self = this;
      if (index == _self.currentIndex) {
        return;
      }
      _self.currentIndex = index;
      _self.$router.replace(path);
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  background: #f9f9f9;
  .content {
    background: #fafafa;
  }
}
.con {
  display: flex;
  margin-top: 60px;
  background: #f9f9f9;
  .tab {
    width: 190px;
    height: 100%;
    box-sizing: border-box;
    margin-right: 30px;
    border-radius: 10px;
    background: #fff;
    h3 {
      font-size: 20px;
      font-weight: bold;
      padding: 30px 15px;
      box-sizing: border-box;
    }
    h3::before {
      content: "";
      display: inline-block;
      background: url("../../assets/img/home/pre-icon.png") center center
        no-repeat;
      background-size: 100%;
      width: 10px;
      height: 10px;
      margin-right: 6px;
    }
    li {
      padding: 15px 35px;
      box-sizing: border-box;
      // width: 100%;
      border-right: 2px solid #fff;
      margin-bottom: 20px;
    }
    li:hover {
      cursor: pointer;
      color: $color-main;
      border-right: 2px solid $color-main;
      background: #fff7f1;
    }
    li.active {
      color: $color-main;
      border-right: 2px solid $color-main;
      background: #fff7f1;
    }
  }
}
</style>
